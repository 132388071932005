angular.module('site.controllers')
    .controller('userDetailsController', ['$scope', '$http', '$window', function($scope, $http, $window) {

        $scope.unsubscribe = function (userId) {
            $http.post('/usersapi/unsubscribe', {
                userId: userId
            }).success(function(data) {
                $window.location.reload();
            });
        };

        $scope.changePassword = function (userId) {
            var randomString = Math.random().toString(36).slice(-8);
            var newPassword = prompt('Ny adgangskode:', randomString);

            if (!newPassword)
                return;

            $http.post('/usersapi/changepassword', {
                userId: userId,
                password: newPassword
            }).success(function(data) {
                alert('Adgangskoden er opdateret.');
            });
        };

        $scope.saveChanges = function (userId, name, addressLine, postalCode, city, phone, email) {
            var randomString = Math.random().toString(36).slice(-8);
            var newPassword = prompt('Ny adgangskode:', randomString);

            if (!newPassword)
                return;

            $http.post('/usersapi/saveUserChanges', {
                userId: userId,
                password: newPassword
            }).success(function (data) {
                alert('Adgangskoden er opdateret.');
            });
        };

    }
]);
