angular.module('site.controllers')
    .controller('giftcardsListController', ['$scope', '$http', '$timeout', '$location', function($scope, $http, $timeout, $location) {

        var searchPromise;

        $scope.data = {};
        $scope.data.query = '';
        $scope.giftcards = [];

        $scope.init = function () {

        };

        $scope.submitSearch = function(e) {
            e.preventDefault();
        };

        $scope.loadGiftcards = function (e) {
            console.log(e);
            if (e) {
                e.preventDefault();
            }

            $http.get('/giftcardsapi/search/?query=' + $scope.data.query)
                .success(function(data) {
                    $scope.giftcards = data;
                });
        }

    }]);
