angular.module('site.controllers')
    .controller('dashboardController', ['$scope', '$http', function($scope, $http) {
        $scope.model = {
            stats: {}
        };

        $scope.init = function() {

            $http.get('/dashboardapi/stats')
                .success(function(data) {
                    $scope.model.stats = data;
                });

            $http.get('http://delivery.wap.local.novicell.dk/statuses')
                .success(function (data) {
                    console.log(data);
                    $scope.model.deliveryStatuses = data;
                });

        };

    }]);
