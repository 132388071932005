angular.module('site.controllers')
    .controller('redirectListController', ['$scope', '$http', '$timeout', '$location', function($scope, $http, $timeout, $location) {

        var searchPromise;
        
        $scope.init = function () {
            var lastQuery = $location.path('/search');
            $scope.pendingSearch = true;

            if (lastQuery && lastQuery.search && lastQuery.search().q) {
                $scope.pendingSearch = true;
                restoreState(lastQuery.search());
            } else {
                $scope.pendingSearch = false;
                $scope.searchQuery = '';
                //loadRedirects(''); 
                $('#q').focus();
            }
            
            $scope.filterRole = 'All';
        };

        $scope.initInvalid = function () {
            loadInvalidRedirects();
        }

        $scope.submitSearch = function(e) {
            e.preventDefault();
        };

        $scope.inputChanged = function () {

            $scope.pendingSearch = true;
            if (searchPromise)
                $timeout.cancel(searchPromise);
            
            if ($scope.searchQuery) {
                searchPromise = $timeout(function() {
                    $scope.completeSearch = false;
                    loadRedirects($scope.searchQuery);
                }, 400);
            } else {
                console.log("test");
                $scope.pendingSearch = false;
                $scope.searchQuery = '';
                $scope.redirects = [];
            }
        };

        $scope.orderBy = function (orderBy) {
            loadRedirects($scope.searchQuery, orderBy);
        };

        function loadRedirects(query, orderBy) {
            $scope.pendingSearch = true;

            maintainState();

            var url = '/redirectsapi/getallredirects?query=' + query + "&orderBy=" + orderBy;

            $http.get(url)
                .success(function(data) {
                    $scope.redirects = data;
                    $scope.pendingSearch = false;
                });
        }

        function loadInvalidRedirects() {
            $scope.pendingSearch = true;

            var url = '/redirectsapi/getinvalidredirects';

            $http.get(url)
                .success(function (data) {
                    $scope.redirects = data;
                    $scope.pendingSearch = false;
                });
        }

        function maintainState() {
            $location.path('/search').search({
                q: $scope.searchQuery,
                inProgress: $scope.pendingSearch.toString(),
            });
        }

        function restoreState(query) {
            $scope.searchQuery = query.q;
            $scope.pendingSearch = query.inProgress == 'true';
            $scope.completeSearch = query.all == 'true';
            loadRedirects(query.q);
       }

    }]);
