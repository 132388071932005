$(document).ready(function () {

    /*
     *  Show messages for order lines. 
     */
    $('tr.no-weight').on('click', function () {
        alert('Varen mangler vægtangivelse.');
    });

    /*
     *  Handle click instead of swipe.
     */
    $('#slider').on('click', function (e) {

        if (!$(e.target).hasClass('hasMore'))
            return;

        var swipeArea = 130;

        var windowWidth = window.innerWidth;
        var swipeLeftArea = swipeArea;
        var swipeRightArea = windowWidth - swipeArea;

        var clickedX = e.offsetX;

        if (clickedX <= swipeLeftArea)
            window.mySwipe.prev();

        if (clickedX >= swipeRightArea && clickedX <= windowWidth)
            window.mySwipe.next();
    });
});