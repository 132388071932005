angular.module('site.controllers')
    .controller('voucherCodeGroupsController', ['$scope', '$http', '$timeout', '$location', function ($scope, $http, $timeout, $location) {
        var vm = this;

        $scope.init = function () {
            loadVoucherGroups();

            $scope.isDisplayDetails = false;
            $scope.detailsHeaderName = "";

            vm.activePrefixId = 0;
            vm.activeVoucherPrefix = null;
            vm.activeCodePrefix = null;
            vm.activePromoCode = null;
            vm.activePromocodeId = null;

        }

        $scope.loadVoucherGroupDetails = function (prefixId, voucherPrefix, codePrefix, activePromoCode, activePromoCodeId) {

            vm.activePrefixId = prefixId;
            vm.activeVoucherPrefix = voucherPrefix;
            vm.activeCodePrefix = codePrefix;
            vm.activePromoCode = activePromoCode;
            vm.activePromoCodeId = activePromoCodeId;

            loadGroupDetails();
        }

        $scope.deleteVoucherCode = function (voucherCodeId) {
            var url = "/vouchersapi/DeactivateCode/?voucherCodeId=" + voucherCodeId;

            $http.get(url)
                .success(function (data) {
                    loadVoucherGroups();
                    loadGroupDetails();
                });
        }

        $scope.deleteActiveVoucherCodesByPrefixId = function () {
            if (vm.activePrefixId) {
                if (confirm("Dette vil deaktivere " + $scope.activeCodes.length + " koder. Bekræft for at fortsætte. ")) {
                    var url = "/vouchersapi/DeactivateVouchersByPrefixId/?voucherCodePrefixId=" + vm.activePrefixId;
                    $http.get(url)
                        .success(function (data) {
                            loadVoucherGroups();
                            loadGroupDetails();
                        });
                }
            }
        }

        function loadVoucherGroups() {
            var voucherId = document.getElementById('inputVoucherId').value;

            var url = "/vouchersapi/VoucherCodeGroups/?voucherId=" + voucherId;

            $http.get(url)
                .success(function (data) {
                    $scope.codeGroups = mapVoucherGroupData(data);

                });

        }

        /// Loads lists of active and deactivated codes for selected voucherGroup
        function loadGroupDetails() {
            $scope.activeCodes = [];
            $scope.inactiveCodes = [];

            var url = "";
            if (!vm.activePromoCodeId) {
                url = "/vouchersapi/VoucherCodeGroupDetails/?voucherCodePrefixId=" + vm.activePrefixId;
            } else {
                url = "/vouchersapi/VoucherCodeGroupDetails/?voucherCodeId=" + vm.activePromoCodeId;
            }
            
            var urlActive = url + "&isDeleted=false";
            var urlInactive = url + "&isDeleted=true";
            var name = '';
            if (vm.activeVoucherPrefix)
                name += vm.activeVoucherPrefix + "-";
            if (vm.activeCodePrefix)
                name += vm.activeCodePrefix;
            else if (vm.activePromoCode)
                name += vm.activePromoCode;

            $scope.detailsHeaderName = name;

            $http.get(urlActive)
                .success(function (data) {
                    $scope.activeCodes = mapVoucherGroupDetailsData(data);
                    $scope.isDisplayDetails = true;
                });
            $http.get(urlInactive)
                .success(function (data) {
                    $scope.inactiveCodes = mapVoucherGroupDetailsData(data);
                    $scope.isDisplayDetails = true;
                });
        }

        
        function mapVoucherGroupData(data) {
            var list = [];
            if (data) {
                for (var i = 0; i < data.length; i++) {
                    var voucherGroup = data[i];
                    var element = {};
                    element.VoucherCodePrefixId = voucherGroup.VoucherCodePrefixId;
                    element.VoucherCodePrefix = voucherGroup.VoucherCodePrefix;
                    element.VoucherPrefix = voucherGroup.VoucherPrefix;
                    element.PromoCode = voucherGroup.PromoCode;
                    element.PromoCodeId = voucherGroup.PromoCodeId;
                    element.Count = voucherGroup.Count;
                    element.ActiveCount = voucherGroup.ActiveCount;
                    element.DateCreated = getDateFromJsonFormat(voucherGroup.DateCreated);

                    list.push(element);
                }
            }
            return list;
        }

        function mapVoucherGroupDetailsData(data) {
            var list = [];
            if (data) {
                for (var i = 0; i < data.length; i++) {
                    var groupDetails = data[i];
                    var element = {};
                    element.Code = groupDetails.Code;
                    element.VoucherId = groupDetails.VoucherId;
                    element.IsUsed = groupDetails.IsUsed;
                    element.VoucherCodeId = groupDetails.VoucherCodeId;
                    element.DateStamp = getDateFromJsonFormat(groupDetails.DateStamp);
                    element.UsedDateStamp = getDateFromJsonFormat(groupDetails.UsedDateStamp);
                    element.VoucherCodePrefixId = groupDetails.VoucherCodePrefixId;
                    element.Prefix = groupDetails.Prefix;
                    element.MaxUsages = groupDetails.MaxUsages;
                    element.TotalUsages = groupDetails.TotalUsages;
                    element.MaxProductAmount = groupDetails.MaxProductAmount;
                    element.Deleted = groupDetails.Deleted;

                    list.push(element);

                }
            }
            return list;
        }

        function getDateFromJsonFormat(jsonFormattedDate) {
            value = jsonFormattedDate != null ? new Date(parseInt(jsonFormattedDate.replace("/Date(", "").replace(")/", ""), 10)) : null;
            return value;
        }

    }]);
