angular.module('site.controllers')
    .controller('orderListController', ['$scope', '$http', '$timeout', '$location', function ($scope, $http, $timeout, $location) {

        var searchPromise;

        $scope.data = {};
        $scope.data.query = '';
        $scope.orders = [];
        $scope.showLoader = false;

        $scope.init = function () {
            var orderNumber = getQueryString('search')
            var submitButton = document.getElementById("js-submit-button");
            if (orderNumber) {
                $scope.data.query = orderNumber
                if (submitButton) {
                    setTimeout(function () {
                        submitButton.click();
                    }, 500);
                }
            }
        };

        $scope.submitSearch = function (e) {
            e.preventDefault();
        };

        $scope.loadOrders = function (e) {
            $scope.showLoader = true;
            console.log(e);
            if (e) {
                e.preventDefault();
            }

            var url;
            url = '/ordersapi/search/?query=' + $scope.data.query;
            $http.get(url).success(function (data) {
                $scope.orders = data;
                $scope.showLoader = false;
                // $scope.pendingSearch = false;
            }).error(function () {
                $scope.showLoader = false;
            });

        }
        function getQueryString(field, url) {
            var href = url ? url : window.location.href;
            var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
            var string = reg.exec(href);
            return string ? string[1] : null;
        };
    }]);
