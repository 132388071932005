angular.module('site.controllers')
    .controller('orderDetailsController', ['$scope', '$http', '$window', function ($scope, $http, $window) {

        $scope.resendConfirmation = function (orderId) {
            $http.get('/ordersapi/resendorderconfirmation?id=' + orderId)
                .success(function (data) {
                    alert('Ordrebekræftelsen er gensendt');
                    $window.location.reload();
                });

        };

        $scope.resendFollowup = function (orderId) {
            $http.get('/ordersapi/resendorderfollowup?id=' + orderId)
                .success(function (data) {
                    alert('Followup er gensendt');
                    $window.location.reload();
                });

        };

        $scope.resendToNavision = function (orderId) {
            $http.get('/ordersapi/resendtonavision?id=' + orderId)
                .success(function (data) {
                    alert('Order sendt til Navision');
                });

        };

        $scope.gdprDeletion = function (orderId, email, languageNodeId) {
            $http.get('/ordersapi/gdprDeletion?id=' + orderId + '&email=' + email + '&languageNodeId=' + languageNodeId)
                .success(function (data) {
                    alert('GDPR sletning anmodet');
                    $window.location.reload();
                });

        };

        $scope.updateEmail = function (orderId) {
            var email = $('#emailInput').val();

            $http.get('/ordersapi/updateEmail?id=' + orderId + '&email=' + email)
                .success(function (data) {
                    alert('Email opdateret');
                });
        };

        $scope.saveGiftCard = function (orderId, sku) {
            var email = $('#giftCardEmail_' + sku).val();
            var receiverName = $('#giftCardReceiverName_' + sku).val();
            var senderName = $('#giftCardSenderName_' + sku).val();
            var personalMessage = $('#giftCardPersonalMessage_' + sku).val();

            $http.post('/ordersapi/UpdateGiftcardInWeborder', {
                BasketId: orderId,
                Sku: sku,
                Email: email,
                SenderName: senderName,
                RecieverName: receiverName,
                PersonalMessage: personalMessage
            }).success(function(data) {
                alert('Gavekort oplysningerne er gemt');
            });
        };

        $scope.resendGiftCard = function (orderId, sku, code, validTo) {
            var email = $('#giftCardEmail_' + sku).val();
            var receiverName = $('#giftCardReceiverName_' + sku).val();
            var senderName = $('#giftCardSenderName_' + sku).val();
            var personalMessage = $('#giftCardPersonalMessage_' + sku).val();

            //Save updated information 
            $http.post('/ordersapi/UpdateGiftcardInWeborder', {
                BasketId: orderId,
                Sku: sku,
                Email: email,
                SenderName: senderName,
                RecieverName: receiverName,
                PersonalMessage: personalMessage
            }).success(function(data) {
                //resend giftcard
                $http.post('/ordersapi/ResendGiftcardEmail', {
                    BasketId: orderId,
                    Sku: sku,
                    Code: code,
                    ValidTo: validTo,
                }).success(function(data) {
                    alert('Gavekort er gensendt');
                });
            });
        };
    }]);
