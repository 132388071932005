angular.module('site.controllers', []);
angular.module('site.services', []);
angular.module('site.directives', []);
angular.module('site.filters', []);

angular.module('site', ['site.filters', 'site.services', 'site.directives', 'site.controllers'])
        .value('$anchorScroll', angular.noop)
        .constant('config', {
            hashPrefix: '!'
        })
    .config(['config', '$httpProvider', '$locationProvider', function (config, $httpProvider, $locationProvider) {
        $locationProvider.html5Mode(false).hashPrefix(config.hashPrefix);
    }]);