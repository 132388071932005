angular.module('site.controllers')
    .controller('userListController', ['$scope', '$http', '$timeout', '$location', function($scope, $http, $timeout, $location) {

        var searchPromise;

        $scope.init = function() {
            var lastQuery = $location.path('/search');

            if (lastQuery && lastQuery.search && lastQuery.search().q) {
                $scope.showInProgress = true;
                restoreState(lastQuery.search());
                search(lastQuery.search().q);
            }
        };

        $scope.inputChanged = function() {
            $scope.pendingSearch = true;

            if (searchPromise)
                $timeout.cancel(searchPromise);

            searchPromise = $timeout(function() {
                search($scope.searchQuery)
                .then(function() {
                    $scope.pendingSearch = false;
                });
            }, 400);
        };

        function search(query) {
            maintainState();
            return $http.get('/usersapi/search?q=' + query) 
                .success(function (data) {
                    $scope.users = data;
                });
        }

        function maintainState() {
            $location.path('/search').search({
                q: $scope.searchQuery
            });
        }

        function restoreState(query) {
            $scope.searchQuery = query.q;
        }
    }
    ]);
