angular.module('site.controllers')
    .controller('klinikController', ['$scope', '$http', '$window', function ($scope, $http, $window) {
        $scope.model = {};
        $scope.model.searchQuery = "";
        $scope.model.searchAll = false;
        $scope.Orders = {};
        $scope.showSearch = true;
        $scope.Order = {};


        $scope.showSearchWindow = function () {
            $scope.showSearch = true;
        }

        $scope.init = function () {
            $scope.search(false);
        };

        $scope.search = function (all) {
            if ($scope.model.searchAll) {
                $scope.searchOrders($scope.model.searchQuery, all);
            } else {
                $scope.searchOrdersAwaitingSignature($scope.model.searchQuery, all);
            }
        };

        $scope.inputChanged = function () {
            $scope.search(false);
        };

        $scope.changeFilter = function (e, b) {
            $scope.model.searchAll = b;
            $scope.search(false);
        };


        $scope.searchAllOrders = function () {
            $scope.search(true);
        };

        $scope.searchOrdersAwaitingSignature = function (searchTerm, all) {
            var days = 14;
            if (all)
                days = 0;

            $http({
                method: 'GET',
                url: '/klinikapi/getorders/?status=NewAwaitingSignature&days=' + days + '&term=' + searchTerm
            }).then(function successCallback(response) {
                $scope.Orders = response.data;
            }, function errorCallback(response) {
                console.log(JSON.stringify(response));
            });

        };

        $scope.searchOrders = function (searchTerm, all) {
            var days = 14;
            if (all)
                days = 0;

            $http({
                method: 'GET',
                url: '/klinikapi/getorders/?days=' + days + '&term=' + searchTerm
            }).then(function successCallback(response) {
                $scope.Orders = response.data;
            }, function errorCallback(response) {
                console.log(JSON.stringify(response));
            });

        };

        $scope.getOrder = function (item) {
            $scope.showSearch = false;

            $http({
                method: 'GET',
                url: '/klinikapi/getorder/?id=' + item.OrderGuid
            }).then(function successCallback(response) {
                $scope.Order = response.data;
            }, function errorCallback(response) {
                console.log(JSON.stringify(response));
            });

        };

        $scope.orderApprove = function () {
            $http({
                method: 'GET',
                url: '/klinikapi/orderapprove/?id=' + $scope.Order.OrderGuid
            }).then(function successCallback(response) {
                $scope.showSearch = true;
                $scope.init();
            }, function errorCallback(response) {
                console.log(JSON.stringify(response));
            });

        };

    }]);
