angular.module('site.controllers')
    .controller('voucherDetailsController', ['$scope', '$http', '$timeout', '$location', function ($scope, $http, $timeout, $location) {

        var vm = this;
        $scope.siteId;
        $scope.displayTargetsInput = false;
        $scope.displayTargetsPreview = false;
        $scope.targetPreviewList = [];

        $scope.targetListType = null;

        $scope.init = function () {
            $scope.updateTargetsInputDisplay();

            // Initiate ng-model for siteId input - minor hack (angular dont like the form being populated from serverside)
            if (document.querySelector('#hiddenSiteId') != null) {
                $scope.siteId = parseInt(document.querySelector('#hiddenSiteId').value);
            }
            else if (document.querySelector("#selectSiteId") != null) {
                $scope.siteId = parseInt(document.querySelector("#selectSiteId").selectedIndex = 2);
            }
        };

        $scope.typeChanged = function () {

        }

        $scope.updateSiteId = function () {

            $scope.updateTargetsInputDisplay();
            $scope.lookupVoucherTargetItems();
        };

        $scope.updateTargetsInputDisplay = function () {
            var voucherTypesWithTarget = [1, 3, 5, 6];
            var voucherTypesWithPreview = [1, 3];
            $scope.displayTargetsInput = voucherTypesWithTarget.indexOf(parseInt($scope.voucherTypeId)) != -1;
            $scope.displayTargetsPreview = voucherTypesWithPreview.indexOf(parseInt($scope.voucherTypeId)) != -1;

            var voucherType = parseInt($scope.voucherTypeId);

            switch (voucherType) {

                case 2://PercentOffOrder
                case 4://AmountOffOrder
                    $scope.targetListType = 'none';
                    break;

                case 3://AmountOffProduct
                case 1://PercentOffProduct
                    $scope.targetListType = 'products';
                    break;

                case 5://PercentOffBrand
                case 6://AmountOffBrands
                    $scope.targetListType = 'brands';
                    break;
            }
        }

        $scope.updatePercentSignDisplay = function () {

            var percentSign = $('#percentSign');
            var voucherType = parseInt($scope.voucherTypeId);

            if (voucherType == 1 || voucherType == 2 || voucherType == 5) {
                percentSign.show();
            }
            else {
                percentSign.hide();
            }
        }

        $scope.lookupVoucherTargetItems = function () {
            var targetIds = $scope.voucherTargets;

            if (!($scope.targetListType === "products") || !targetIds) {
                return;
            }

            var indexName = '';
            switch (parseInt($scope.siteId)) {
                case 1:
                    indexName = 'goshopping_en';
                    break;
                case 2:
                    indexName = 'goshopping_da';
                    break;
                case 3:
                    indexName = 'goshopping_sv';
                    break;
                case 4:
                    indexName = 'goshopping_no';
                    break;
                case 5:
                    indexName = 'goshopping_fi';
                    break;
            }

            var apiUrl = searchApiUrl + "/find-name/" + indexName + "/?indexName=" + indexName + "&targetIds=" + targetIds;

            $http.get(apiUrl)
                .success(function (data) {
                    console.log(data);
                    if (data != null && data.length > 0) {
                        $scope.targetPreviewList = data;
                        $scope.displayTargetsPreview = true;
                    } else {
                        $scope.targetPreviewList = [];
                        $scope.displayTargetsPreview = false;
                    }
                });

        }

    }]);
